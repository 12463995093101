<template>
    <div class="sup_content sup_cookie_category">
        <!--- Create New Role --->
        <div class="sup_page_heading">
            <h2>Cookie Category</h2>
            <Button icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add Cookie Category'" @click="cookieCatModals.addCategory = true" />
        </div>
        <div class="sup_inner_content">
            <!--- Show Language list Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allCookieCategoryData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allCookieCategoryData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading Cookie Category data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button :label="allCookieCategoryData.activityStatus === 'active' ? 'Inactive List': 'Active List'" :icon="['pi', allCookieCategoryData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allCookieCategoryData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllCookieCategory(allCookieCategoryData.activityStatus === 'active' ? 'de-active':'active', 'cookieCatStatus')" />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allCookieCategoryData.search" placeholder="Search Cookie Category" @keyup.enter="fetchAllCookieCategory(allCookieCategoryData.activityStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allCookieCategoryData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allCookieCategoryData.dateRange[1] != null ? fetchAllCookieCategory(allCookieCategoryData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allCookieCategoryData.search !== null || allCookieCategoryData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllCookieCategory(allCookieCategoryData.activityStatus, 'resetFilters')"/>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Language found...
                </template>
                <Column field="categoryName" header="Category Name" :sortable="true">
                    <template #body="slotProps">
                        <strong>{{capitalizeCase(slotProps.data.categoryName)}}</strong>
                    </template>
                </Column>

                <!-- <Column field="categoryDescription" header="Category Description">
                </Column> -->

                <Column field="createdAt" header="Created On">
                    <template #body="slotProps">
                    {{usFormatDate(slotProps.data.createdAt)}}
                    </template>
                </Column>

                <Column field="status" header="Status" headerStyle="8rem">
                    <template #body="slotProps">
                        <span :class="['sup_item_status ', slotProps.data.status ? 'sup_item_status_success' : 'sup_item_status_danger']"><i :class="['pi ', slotProps.data.status ? 'pi-check' : 'pi-times']"></i>{{slotProps.data.status ? 'Active' : 'In-Active'}}</span>
                    </template>
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-primary p-mr-1" @click="editCookieCat(slotProps.data)" v-tooltip="'Edit Cookie Category'" />
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="confirmationModalfn(slotProps.data, 'delete')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore Language'" @click="confirmationModalfn(slotProps.data, 'restore')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-trash" class="p-button-danger p-button-rounded p-button-sm"  v-tooltip="'Delete Language'" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Language list End --->
        </div>
        <!--- Add Cookie category Modal Start --->
        <Dialog header="Add New Cookie Category" v-model:visible="cookieCatModals.addCategory" :modal="true" :style="{width: '600px'}" @hide="close()">
            <form class="sup_language_add" @submit.prevent="createCookieCategory()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="category_name">Category Name*</label>
                        <InputText v-model="addEditCookieCat.catName"  id="category_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCookieCatError.nameEmpty">Category Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="language_code">Language Code*</label>
                        <Dropdown v-model="addEditCookieCat.langCode" :options="setlangCode" id="language_code" optionLabel="name" optionValue="code" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCookieCatError.langCodeEmpty">Language Code is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="category_description">Category Description*</label>
                        <Textarea v-model="addEditCookieCat.catDesc" id="category_description" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCookieCatError.catDescEmpty">Category Description is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allCookieCategoryData.addStatus === 200 ? 'success' : 'error']" v-if="allCookieCategoryData.addStatus !== ''">{{allCookieCategoryData.addMessage}}</div >
            </form>
        </Dialog>
        <!--- Add Cookie category Modal End --->
        <!--- Edit Cookie category Modal Start --->
        <Dialog header="Edit Cookie Category" v-model:visible="cookieCatModals.editCategory" :modal="true" :style="{width: '600px'}" @hide="close()">
            <form class="sup_language_add" @submit.prevent="updateCookieCategory(cookieCatModals.editCategoryData)">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="category_name">Category Name*</label>
                        <InputText v-model="addEditCookieCat.catName"  id="category_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCookieCatError.nameEmpty">Category Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="language_code">Language Code*</label>
                        <Dropdown v-model="addEditCookieCat.langCode" :options="setlangCode" id="language_code" optionLabel="name" optionValue="code" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCookieCatError.langCodeEmpty">Language Code is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="category_description">Category Description*</label>
                        <Textarea v-model="addEditCookieCat.catDesc" id="category_description" rows="5" />
                        <small class="p-invalid sup_text_danger" v-if="addEditCookieCatError.catDescEmpty">Category Description is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allCookieCategoryData.addStatus === 200 ? 'success' : 'error']" v-if="allCookieCategoryData.addStatus !== ''">{{allCookieCategoryData.addMessage}}</div >
            </form>
        </Dialog>
        <!--- Edit Cookie category Modal End --->
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteAndRestoreCookieCat(confirmationModal.modaldata.data, confirmationModal.modaldata.load), confirmationModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->
    </div>
</template>

<script>
import { onBeforeMount, reactive, ref } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { allCookieCategory, allLanguageCode, CookieCategoryByID } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import { formatDate, usFormatDate, capitalizeCase } from "../../../../helper/utility/utility";

export default {
    name: "CookieCategory",
    setup(){
        //Store call
        let store = useStore();

        //Fetch and filtring data initial state
        const allCookieCategoryData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'active',
            search: null,
            dateRange: null,
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: '',
        });

        const cookieCatModals = reactive({
            addCategory: false,
            editCategory: false,
            editCategoryData: false,
        });

        const setlangCode = ref('');

        const addEditCookieCat = reactive({
            catName: '',
            langCode: '',
            catDesc: ''
        });

        const addEditCookieCatError = reactive({
            nameEmpty: false,
            langCodeEmpty: false,
            catDescEmpty: false
        })

        const confirmationModal = reactive({
            modalState: false,
            modaldata: ''
        })

        //Before component mount required function invoke
        onBeforeMount(async () => {
            fetchAllCookieCategory('active');
            fetchLanguageCode();
        });

        const fetchAllCookieCategory = async(status, activity) => {
            allCookieCategoryData.activityStatus = status;
            let date = null;
            if(activity === 'languageStatus'){
                allCookieCategoryData.activityStatus = status === 'active' ? 'de-active' : 'active' ;
            }

            if(activity === 'resetFilters'){
                allCookieCategoryData.search = null;
                allCookieCategoryData.dateRange = null;
            }

            if (activity === 'dateRange') {
                date = formatDate(allCookieCategoryData.dateRange[0]) + ' ' + formatDate(allCookieCategoryData.dateRange[1]);
            }

            try{
                allCookieCategoryData.loading = !allCookieCategoryData.loading;
                const response = await axios.get(allCookieCategory, {
                    params: {
                        status: allCookieCategoryData.activityStatus,
                        search: allCookieCategoryData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                
                allCookieCategoryData.data = response.data.data.cookieCategorie;
                allCookieCategoryData.total = response.data.data.total;
                allCookieCategoryData.loading = !allCookieCategoryData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        //Langage code data list fetching
        const fetchLanguageCode = async() => {
            try{

                const response = await axios.get(allLanguageCode, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                setlangCode.value = response.data.data;
                
            } catch(err){
                setlangCode.value = null;
            }
        }

        const close = () => {
            addEditCookieCat.catName = '';
            addEditCookieCat.catDesc = '';
            addEditCookieCat.langCode = '';
        }

        const confirmationModalfn = (sloteData, load) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                load: load
            }
        }

        //Cookie Category active/deavtive mode function
        const deleteAndRestoreCookieCat = async (sloteData, type) => {
            try{
                const options = {
                    method: type === 'delete' ? 'delete' : 'patch',
                    url: allCookieCategory,
                    params: {
                        uuid: sloteData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllCookieCategory('active');
            } catch(err){
                console.log(err);
            }
        }

        const editCookieCat = async (slotData) => {
            cookieCatModals.editCategoryData = slotData;
            const response = await axios.get(CookieCategoryByID,{
                params: {
                    uuid : slotData.uuid
                },
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                }
            });

            if (response.data.status === 200) {
                cookieCatModals.editCategory = true;
                cookieCatModals.editCategoryData = slotData;
                addEditCookieCat.catName = response.data.data.categoryName;
                addEditCookieCat.catDesc = response.data.data.categoryDescription.en;
                addEditCookieCat.langCode = "en";
            }
        }

        const updateCookieCategory = async (slotData) => {
            try {
                
                const desc = {};
                desc[addEditCookieCat.langCode] = addEditCookieCat.catDesc;
                const response = await axios({
                    method: 'put',
                    url: allCookieCategory,
                    params: {
                        uuid: slotData.uuid
                    },
                    data: JSON.stringify({
                        categoryName: addEditCookieCat.catName,
                        categoryDescription: desc,
                    }),
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                            // transform the data
                            return data;
                    }]
                });
                
                if (response.data.status === 200) {
                    cookieCatModals.editCategory = true;
                    cookieCatModals.editCategoryData = slotData;
                    addEditCookieCat.catName = response.data.data.categoryName;
                    addEditCookieCat.catDesc = response.data.data.categoryDescription.en;
                    addEditCookieCat.langCode = "en";
                    allCookieCategoryData.editMessage = response.data.message;
                    allCookieCategoryData.editStatus = response.data.status;
                }

            } catch(error) {
                console.log(error);
                allCookieCategoryData.editMessage = error.response.message;
                allCookieCategoryData.editStatus = error.response.status;
            }
        }

        const createCookieCategory = () => {
            let isValidate = cookieCategorieValidation();
            console.log(isValidate);
        }

        const cookieCategorieValidation = () =>{
            if (addEditCookieCat.catName === '') {
                addEditCookieCatError.nameEmpty = true;
            } else {
                addEditCookieCatError.nameEmpty = false;
            }

            if (addEditCookieCat.catDesc === '') {
                addEditCookieCatError.catDescEmpty = true;
            } else {
                addEditCookieCatError.catDescEmpty = false;
            }

            if (addEditCookieCat.langCode === '') {
                addEditCookieCatError.langCodeEmpty = true;
            } else {
                addEditCookieCatError.langCodeEmpty = false;
            }

            if (!addEditCookieCatError.nameEmpty && !addEditCookieCatError.langCodeEmpty && !addEditCookieCatError.catDescEmpty) {
                return true;
            } else {
                return false;
            }
        }

        return{
            allCookieCategoryData,
            cookieCatModals,
            addEditCookieCat,
            addEditCookieCatError,
            setlangCode,
            confirmationModal,
            fetchAllCookieCategory,
            usFormatDate,
            capitalizeCase,
            close,
            confirmationModalfn,
            deleteAndRestoreCookieCat,
            editCookieCat,
            updateCookieCategory,
            createCookieCategory
        }
    }
}
</script>

<style>

</style>